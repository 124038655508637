<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Agosto 2024                                                     ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <section class="d-flex">
      <!-- Filtrar por Usuario -->
      <v-text-field class="me-2 filtro" v-model="buscar.usuario" label="Usuario" outlined dense hide-details color="primary">
      </v-text-field>
      <!-- Filtrar por Nombre del usuario -->
      <v-text-field class="me-2 filtro" v-model="buscar.nombre" label="Nombre" outlined dense hide-details color="primary">
      </v-text-field>
      <!-- Filtrar por Empresa -->
      <v-autocomplete ref="buscarEmpresa" class="me-2 filtro" v-model="buscar.empresa" label="Empresa" outlined dense hide-details color="primary"
        :items="lista.empresas" :menu-props="{ offsetY: true }" no-data-text="No se encontraron resultados.">
      </v-autocomplete>
      <!-- Filtrar por estado -->
      <v-autocomplete class="me-2 filtro" v-model="buscar.estado" label="Estado" outlined dense hide-details color="primary"
        :items="lista.estados" :menu-props="{ offsetY: true }" no-data-text="No se encontraron resultados.">
      </v-autocomplete>
      <!-- Este botón abre un diálogo para agregar una nueva relación -->
      <div>
        <v-tooltip left color="success" :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small fab color="success" v-bind="attrs" v-on="on" @mousedown.prevent="dialogoUsuarioEmpresa = true">
              <v-icon> add </v-icon>
            </v-btn>
          </template>
          <span>Agregar</span>
        </v-tooltip>
      </div>
    </section>

    <!-- Diálogo para crear una relación usuario empresa -->
    <v-dialog v-model="dialogoUsuarioEmpresa" transition="dialog-bottom-transition" max-width="48rem" :editar="false" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6">Crear relación</span>
        </v-card-title>
        <v-card-text class="dialogo">
          <!-- Campo para ingresar la empresa -->
          <v-autocomplete v-model="crearUsuarioEmpresa.empresa" label="Empresa" dense outlined :items="lista.empresas" ref="crearUsuarioEmpresa"
            :menu-props="{ offsetY: true }" @input="validarUsuarioEmpresa()" color="primary" :rules="rules.required" no-data-text="No se encontraron resultados.">
          </v-autocomplete>
          <div class="d-flex">
            <!-- Campo para filtrar por el usuario -->
            <v-text-field class="me-2" v-model="buscarUsuario.usuario" label="Usuario" dense outlined hide-details
              color="primary">
            </v-text-field>

            <!-- Campo para filtrar por el nombre del usuario -->
            <v-text-field v-model="buscarUsuario.nombre" label="Nombre" dense outlined hide-details color="primary">
            </v-text-field>
          </div>

          <!-- Tabla de usuarios -->
          <v-data-table class="elevation mt-4" :items="tablaUsuarios.items" :loading="tablaUsuarios.loading" fixed-header :headers="tablaUsuarios.headers"
            :footer-props="tablaUsuarios.footerProps" :items-per-page.sync="tablaUsuarios.itemsPerPage" show-select item-key="usuario" 
            v-model="crearUsuarioEmpresa.usuarios" hover  no-data-text="No se encontraron resultados." height="32vh"
            :server-items-length="tablaUsuarios.totalPage" :page.sync="tablaUsuarios.page">
            <template v-slot:[`header.data-table-select`]></template>
            <template v-slot:footer.page-text="items">
              {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
          </v-data-table>

          <!-- Validación -->
          <div v-if="error">
            <span class="error--text">{{ mensajeError }}</span>
          </div>

          <!-- Botones -->
          <div class="d-flex justify-end">
            <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
            <v-btn color="success" :disabled="!formValid || disabled || guardarDisabled" text depressed @click="agregarUsuarioEmpresa()">Guardar</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Tabla de usuario empresa -->
    <v-data-table class="elevation mt-4" :items="tablaUsuarioEmpresa.items" :loading="tablaUsuarioEmpresa.loading" fixed-header 
      :headers="tablaUsuarioEmpresa.headers" :footer-props="tablaUsuarioEmpresa.footerProps" :items-per-page.sync="tablaUsuarioEmpresa.itemsPerPage" 
      :page.sync="tablaUsuarioEmpresa.page" :server-items-length="tablaUsuarioEmpresa.totalPage" height="63vh" hover no-data-text="No se encontraron resultados.">
      <!-- Columna de la empresa -->
      <template v-slot:[`item.empresa`]="{ item }">
        {{ item.idEmpresa.idEmpresa }} - {{ item.idEmpresa.nombre }}
      </template>
      <!-- Columna del estado -->
      <template v-slot:[`item.eliminado`]="{ item }">
        <v-icon :color="item.eliminado === false ? 'success' : 'error'">
          {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
        </v-icon>
        {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
      </template>
      <!-- Columna de las acciones -->
      <template v-slot:[`item.acciones`]="{ item }">
        <!-- Opción cambiar estado de una contraindicación -->
        <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'success'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="abrirDialogoEstado(item)">
              <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
        </v-tooltip>
      </template>
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <!-- dialogo para cambiar el estado -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6">
            {{ mensajeEstado }}
          </span>
        </v-card-title>
        <v-card-text class="dialogo">
          <div>
            <p v-if="cargando" class="text-center ma-0">
              Por favor espere un momento...
              <v-icon :class="{ 'rotate-animation': cargando }" large>rotate_right
              </v-icon>
            </p>
          </div>
          <div class="d-flex justify-end">
            <v-btn v-if="!cargando" class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
            <v-btn v-if="!cargando" color="success" depressed text @click="cambiarEstado()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Alerta -->
    <v-snackbar v-model="snackbar" top color="error" timeout="6000">
      {{ textoSnackbar }}
    </v-snackbar>

    <!-- Recuadro cargando -->
    <div v-if="guardarDisabled" class="overlay">
      <v-alert class="alerta" persistent transition="dialog-bottom-transition">
        <v-icon class="d-flex justify-center rotate-animation" color="white" large>rotate_right</v-icon>
        <span class="d-flex justify-center">Guardando</span>
      </v-alert>
    </div>
  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import { Role } from "../../../../router/role.js";

export default {
  name: "UsuarioEmpresa",
  data() {
    return {
      buscar: {
        usuario: '',
        nombre: '',
        empresa: '',
        estado: '',
      },
      lista: {
        estados: [
          { text: 'Activo', value: false },
          { text: 'Inactivo', value: true },
        ],
        empresas: []
      },
      buscarUsuario: {
        usuario: '',
        nombre: '',
      },
      dialogoUsuarioEmpresa: false,
      crearUsuarioEmpresa: {
        usuarios: [],
        empresa: '',
      },
      lastmodifiedby: '',
      tablaUsuarioEmpresa: {
        items: [],
        loading: false,
        headers: [
          { text: "USUARIO", value: 'usuarios.usuario', width: "10%", sortable: false },
          { text: "NOMBRE", value: 'usuarios.nombre', width: "25%", sortable: false },
          { text: "EMPRESA", value: 'empresa', width: "45%", sortable: false },
          { text: "ESTADO", value: 'eliminado', width: "10%", sortable: false },
          { text: "ACCIONES", value: 'acciones', align: "center", width: "10%", sortable: false },
        ],
        footerProps: {
          'items-per-page-options': [10, 20, 30, 40],
          'items-per-page-text': 'Items por página:',
          'show-current-page': true,
          'show-first-last-page': true
        },
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      },
      tablaUsuarios: {
        items: [],
        loading: false,
        headers: [
          { text: "USUARIO", value: 'usuario', width: "30%", sortable: false },
          { text: "NOMBRE", value: 'nombre', width: "70%", sortable: false },
        ],
        footerProps: {
          'items-per-page-options': [10, 20],
          'items-per-page-text': 'Items por página:',
          'show-current-page': true,
          'show-first-last-page': true
        },
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      },
      dialogoCambiarEstado: false,
      usuarioEmpresaSeleccionado: {},
      mensajeEstado: '',
      userRoles: {},
      roles: Role,
      disabled: false,
      cargando: false,
      error: false,
      mensajeError: '',
      rules: {
        required: [
          v => !!v || "Este campo es requerido.",
        ],
      },
      snackbar: false,
      textoSnackbar: '',
      guardarDisabled: false,
    }
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  computed: {
    ...mapState(["auth", "enterprise"]),
    formValid() {
      return this.crearUsuarioEmpresa.empresa && this.crearUsuarioEmpresa.usuarios.length > 0 && !this.error;
    }
  },
  watch: {
    'tablaUsuarioEmpresa.page': function () {
      this.listarUsuarioEmpresa();
    },
    'tablaUsuarioEmpresa.itemsPerPage': function () {
      this.tablaUsuarioEmpresa.page = 1;
      this.listarUsuarioEmpresa();
    },
    'buscar.usuario': function () {
      this.tablaUsuarioEmpresa.page = 1;
      this.listarUsuarioEmpresa();
    },
    'buscar.nombre': function () {
      this.tablaUsuarioEmpresa.page = 1;
      this.listarUsuarioEmpresa();
    },
    'buscar.empresa': function () {
      this.tablaUsuarioEmpresa.page = 1;
      this.listarUsuarioEmpresa();
    },
    'buscar.estado': function () {
      this.tablaUsuarioEmpresa.page = 1;
      this.listarUsuarioEmpresa();
    },
    'buscarUsuario.usuario': function () {
      this.tablaUsuarios.page = 1;
      this.listarUsuarios();
    },
    'buscarUsuario.nombre': function () {
      this.tablaUsuarios.page = 1;
      this.listarUsuarios();
    },
    'tablaUsuarios.page': function () {
      this.listarUsuarios();
    },
    'tablaUsuarios.itemsPerPage': function () {
      this.tablaUsuarios.page = 1;
      this.listarUsuarios();
    },
    'dialogoUsuarioEmpresa': function (nextValue) {
      if (nextValue === true) {
        this.listarUsuarios();
      }
    },
    'dialogoCambiarEstado': function (nextValue) {
      if (nextValue === false) {
        setTimeout(() => {
          this.cargando = false;
        }, 500)
      }
    },
    'crearUsuarioEmpresa.usuarios': function () {
        this.validarUsuarioEmpresa();
    }
  },
  methods: {
    /**
     * Método para listar las relaciones usuario-empresa.
     */
    listarUsuarioEmpresa() {
      this.tablaUsuarioEmpresa.loading = true;
      this.$http.get(`msa-setting/api/usem/page`, {
        params: {
          page: this.tablaUsuarioEmpresa.page - 1,
          size: this.tablaUsuarioEmpresa.itemsPerPage,
          usuario: this.buscar.usuario.toLowerCase(),
          nombre: this.buscar.nombre.toLowerCase(),
          idEmpresa: this.buscar.empresa,
          eliminado: this.buscar.estado,
        }
      }).then((response) => {
        this.tablaUsuarioEmpresa.items = response.data.content;
        this.tablaUsuarioEmpresa.totalPage = response.data.totalElements;
        this.tablaUsuarioEmpresa.loading = false;
      }).catch((error) => {
        this.handleError(error, 'Error al listar las relaciones');
        this.tablaUsuarioEmpresa.loading = false;
      });
    },
    /**
     * Método para listar los usuarios.
     */
    listarUsuarios() {
      this.tablaUsuarios.loading = true;
      this.$http.get(`msa-setting/api/usuario/list`, {
        params: {
          page: this.tablaUsuarios.page - 1,
          size: this.tablaUsuarios.itemsPerPage,
          usuario: this.buscarUsuario.usuario ? this.buscarUsuario.usuario.toLowerCase() : '',
          nombre: this.buscarUsuario.nombre ? this.buscarUsuario.nombre.toLowerCase() : '',
          estado: 'true',
          isDeleted: false,
        }
      }).then((response) => {
        this.tablaUsuarios.items = response.data.content;
        this.tablaUsuarios.totalPage = response.data.totalElements;
        this.tablaUsuarios.loading = false;
      }).catch((error) => {
        this.handleError(error, 'Error al listar los usuarios');
        this.tablaUsuarios.loading = false;
      });
    },
    /**
     * Método para validar si la relación usuario-empresa ya existe.
     */
    validarUsuarioEmpresa() {
      if (this.crearUsuarioEmpresa.empresa && this.crearUsuarioEmpresa.usuarios.length > 0) {
        const usuarios = this.crearUsuarioEmpresa.usuarios.map(item => item.usuario);
        this.$http.post("msa-setting/api/usem/validate", usuarios, {
          params: {
            idEmpresa: this.crearUsuarioEmpresa.empresa
          }
        }).then((response) => {
          if (response.data !== '') {
            this.error = true;
            this.mensajeError = response.data;
          } else {
            this.error = false;
            this.mensajeError = '';
          }
        }).catch((error) => {
          this.handleError(error, 'Error al validar la existencia de la relación');
          this.disabled = true;
        });
      } else {
        this.error = false;
        this.mensajeError = '';
      }
    },
    /**
     * Método para agregar una nueva relación usuario-empresa.
     */
    agregarUsuarioEmpresa() {
      this.guardarDisabled = true;
      const usemList = this.crearUsuarioEmpresa.usuarios.map(usuarios => ({     
        empresa:{
          idEmpresa: this.crearUsuarioEmpresa.empresa
        },
        usuarios:{
          usuario: usuarios.usuario
        },
        eliminado: false
      }));
      this.$http.post("msa-setting/api/usem/save-list", usemList)
      .then(() => {
        this.guardarDisabled = false;
        this.listarUsuarioEmpresa();
        this.dialogoUsuarioEmpresa = false;
        this.clear();
      }).catch((error) => {
        this.handleError(error, 'Error al agregar la relación');
        this.guardarDisabled = false;
      });
    },
    /**
     * Método para listar las empresas activas.
     */
    listarEmpresa() {
      this.$http.get(`msa-setting/api/empresa/activa`)
      .then((response) => {
        response.data.forEach((item) => {
          this.lista.empresas.push({
            text: `${item.idEmpresa} - ${item.nombre}`,
            value: item.idEmpresa
          });
        });
      }).catch((error) => {
        this.handleError(error, 'Error al listar las empresas');
      });
    },
    /**
     * Método para abrir el diálogo de cambio de estado de una relación usuario-empresa.
     * @param item - La relacion usuario-empresa seleccionada.
     */
    abrirDialogoEstado(item) {
      this.dialogoCambiarEstado = true
      this.usuarioEmpresaSeleccionado = item;
      this.mensajeEstado = this.usuarioEmpresaSeleccionado.eliminado === false ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
    },
    /**
     * Método para cambiar el estado de una relación usuario-empresa (activar/inactivar).
     */
    cambiarEstado() {
      this.cargando = true;
      const estado  = this.usuarioEmpresaSeleccionado.eliminado === false ? true : false;
      const cambiarEstado = {
        idUsem: this.usuarioEmpresaSeleccionado.idUsem,
        eliminado: estado,
        empresa: {
          idEmpresa: this.usuarioEmpresaSeleccionado.idEmpresa.idEmpresa
        },
        usuarios:{
          usuario: this.usuarioEmpresaSeleccionado.usuarios.usuario
        },
      }
      this.$http.post(`msa-setting/api/usem/save-list`, [cambiarEstado])
      .then(() => {
        this.listarUsuarioEmpresa();
        this.dialogoCambiarEstado = false;
      }).catch((error) => {
        this.handleError(error, 'Error al cambiar el estado');
        this.dialogoCambiarEstado = false;
      });
    },
    /**
     * Método para mostrar las alertas cuando genera error el back.
     * @param error - El error generado.
     * @param mensaje - El mensaje de error.
     */
     handleError(error, mensaje) {
      console.log(error);
      this.snackbar = true;
      this.textoSnackbar = `${mensaje}: ${error.response?.data?.message || error.message || error}`;
    },
    /**
     * Método para limpiar los formularios y restablecer el estado del componente.
     */
    clear() {
      this.dialogoUsuarioEmpresa = false;
      this.dialogoCambiarEstado = false;
      this.usuarioEmpresaSeleccionado = {};
      setTimeout(() => {
        this.crearUsuarioEmpresa.empresa = '';
        this.crearUsuarioEmpresa.usuarios = [];
        this.buscarUsuario.usuario = '';
        this.buscarUsuario.nombre = '';
        this.error = false;
        this.mensajeError = '';
        this.disabled = false;
        this.cargando = false;
        this.guardarDisabled = false;
        if (this.$refs.crearUsuarioEmpresa) {
          this.$refs.crearUsuarioEmpresa.reset();
        }
      }, 500)
    },
  },
  mounted() {
    this.listarUsuarioEmpresa();
    this.listarEmpresa();
    this.lastmodifiedby = this.auth.username.toUpperCase();
  }
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 85vh;
}

.filtro {
  width: 25%;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

.dialogo {
  padding-top: 20px !important;
}

::v-deep .elevation .v-data-footer {
  width: 100%;
}

::v-deep .v-select__slot {
  min-width: 46px;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

.rotate-animation {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::v-deep .elevation .v-data-table__checkbox .v-input--selection-controls__input .v-icon,
::v-deep .elevation .v-data-table__checkbox .v-input--selection-controls__input {
  color: #0d47a1;
  margin: 0;
}

::v-deep .elevation .v-data-table__checkbox {
  height: 24px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}

.alerta {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 15vw;
}
</style>